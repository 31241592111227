import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import TableFooter from '@material-ui/core/TableFooter';
import TablePagination from '@material-ui/core/TablePagination';
import PauseCircleOutlineIcon from '@material-ui/icons/PauseCircleOutline';
import PlayCircleOutlineIcon from '@material-ui/icons/PlayCircleOutline';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';

import Preloader from 'components/Preloader/index';
import dayjs from 'dayjs';
import 'dayjs/locale/ru';

dayjs.locale('ru');

const useStyles = makeStyles({
  table: {
    minWidth: 650,
  },
  tableContainer: {
    marginTop: 30,
  },
  tableRow: {
    cursor: 'pointer',
  },
  tableCellAction: {
    cursor: 'pointer',
    transition: 'all 0.3s',
    border: '1px solid rgba(224, 224, 224, 1) !important',
    backgroundColor: 'white',
    '&:hover': {
      backgroundColor: '#3f51b5',
      color: 'white',
    },
  },
});

const ArticleLIst = (props) => {
  const {
    results = [],
    rowsPerPage = 25,
    onChangeRowsPerPage,
    onChangePage,
    handleGoToEdit,
    handleGetArticleList,
    page = 0,
    Actions,
    baseUrl,
    ...rest
  } = props;

  const classes = useStyles();

  const handleChangePage = (event, newPage) => {
    onChangePage(newPage + 1);
  };

  const handleChangeRowsPerPage = (event) => {
    const value = parseInt(event.target.value, 10);
    onChangeRowsPerPage(value);
  };

  return (
    results && results.length > 0 ? (
    <TableContainer className={classes.tableContainer} component={Paper}>
      <Table className={classes.table} size="small" aria-label="a dense table">
        <TableHead>
          <TableRow>
            <TableCell>Заголовок</TableCell>
            {/* <TableCell align="center">Сайт</TableCell> */}
            <TableCell align="center">Опубликована</TableCell>
            <TableCell align="center">Дата публикации</TableCell>
            <TableCell align="center">С обложкой</TableCell>
            <TableCell align="center">С видео</TableCell>
            <TableCell align="center">Действия</TableCell>
            <TableCell align="center">Просмотры</TableCell>
            <TableCell align="center">Автор</TableCell>
          </TableRow>
        </TableHead>

        <TableBody>
          {results.map((row) => (
            <TableRow key={row.id}>
              <TableCell
                component="th"
                scope="row"
                onClick={() => handleGoToEdit(row.slug)}
                className={classes.tableCellAction}
              >
                {row.title}
              </TableCell>

              <TableCell align="center">
                {row.is_published ? (
                  <PlayCircleOutlineIcon color="primary" />
                ) : (
                  <PauseCircleOutlineIcon color="error" />
                )}
              </TableCell>
              <TableCell align="center">
                {dayjs(Number(row.pub_date_at)).format('DD MMMM YYYYг HH:mm')}
              </TableCell>

              <TableCell align="center">
                {row.image_cover ? (
                  <CheckCircleOutlineIcon color="primary" />
                ) : (
                  <HighlightOffIcon color="error" />
                )}
              </TableCell>

              <TableCell align="center">
                {row.is_video ? (
                  <CheckCircleOutlineIcon color="primary" />
                ) : (
                  <HighlightOffIcon color="error" />
                )}
              </TableCell>
              <TableCell align="center">
                <Actions
                  {...row}
                  baseUrl={baseUrl}
                  handleGoToEdit={handleGoToEdit}
                  handleGetArticleList={handleGetArticleList}
                />
              </TableCell>

              <TableCell align="center">{Number(row.shows)}</TableCell>

              <TableCell align="center">
                {row.authors.map((author) => (
                  <div
                    key={author.id}
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                    }}
                  >
                    {!author.is_author && (
                      <HighlightOffIcon
                        color="error"
                        style={{ marginRight: '4px' }}
                      />
                    )}
                    {`${author.first_name || ''} ${author.last_name || ''} `}
                  </div>
                ))}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>

        <TableFooter>
          <TableRow>
            <TablePagination
              rowsPerPageOptions={[10]}
              labelRowsPerPage="Статьи"
              labelDisplayedRows={({ from, to, count }) =>
                `${from}-${to} из ${count !== -1 ? count : to}`
              }
              count={rest?.count || 0}
              rowsPerPage={rowsPerPage}
              page={page}
              onChangePage={handleChangePage}
              onChangeRowsPerPage={handleChangeRowsPerPage}
            />
          </TableRow>
        </TableFooter>
      </Table>
    </TableContainer>
  ) : (
    <Preloader />
  )
  );
};

export default ArticleLIst;
