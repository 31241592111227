import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Form } from 'react-final-form';
import { TextField, Select, Autocomplete } from 'mui-rff';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Paper from '@material-ui/core/Paper';
import { useHistory } from 'react-router-dom';
import MenuItem from '@material-ui/core/MenuItem';
import MuiTextField from '@material-ui/core/TextField';
import {
  createArticle,
  getCategoriesList,
  getArticleTargets,
} from '../../../request/index.js';

const types = [
  { name: 'Новость', value: 'news' },
  { name: 'Лонгрид', value: 'narrative' },
];

const useStyles = makeStyles(() => ({
  root: {
    '& .MuiTextField-root': {
      width: '100%',
    },
  },
  submitBtn: {
    marginTop: 15,
  },
}));

const NewArticle = () => {
  const classes = useStyles();
  const history = useHistory();
  const [categories, setCategories] = useState([]);
  const [targets, setTargets] = useState([]);
  const userID = useSelector((state) => state.auth.id);
  const userIsAuthor = useSelector((state) => state.auth.is_author);
  const permissions = useSelector((state) =>
    state?.auth?.sitePermissions.map(({ name }) => name)
  );

  useEffect(() => {
    async function fetchData() {
      const categoriesList = await getCategoriesList(
        '?per_page=100000&is_active=true'
      );
      const articleTargets = await getArticleTargets();
      const userSitePermissions = articleTargets?.filter(({ name }) =>
        permissions.includes(name)
      );
      setCategories(categoriesList.results);
      setTargets(userSitePermissions);
    }
    fetchData();
  }, []);

  const onSubmit = async (values) => {
    const prepareValues = {
      ...values,
      group_id: null,
      tags_ids: [],
      category_id: values.category.id,
      authors_ids: [userID],
    };
    const { slug } = await createArticle(prepareValues);
    history.push(`/admin/articles/edit/${slug}`);
  };

  const validate = (values) => {
    const errors = {};
    if (!values.title) {
      errors.title = 'Обязательно для заполнения';
    }
    if (!values.type) {
      errors.type = 'Обязательно для заполнения';
    }
    if (!values.target_id) {
      errors.target_id = 'Обязательно для заполнения';
    }
    if (!values.category) {
      errors.category = 'Обязательно для заполнения';
    }
    return errors;
  };

  return (
    <>
      <Paper style={{ padding: 15, marginBottom: 15 }} elevation={0}>
        <b>Создать статью</b>
      </Paper>
      <Form
        onSubmit={onSubmit}
        validate={validate}
        render={({ handleSubmit, valid }) => (
          <form
            onSubmit={handleSubmit}
            className={classes.root}
            noValidate
            autoComplete="off"
          >
            <Paper style={{ padding: 15 }}>
              <Select
                required
                name="target_id"
                label="Выберите сайт"
                formControlProps={{
                  margin: 'dense',
                  variant: 'outlined',
                }}
              >
                {targets.map(({ name, id }) => (
                  <MenuItem key={name} value={id}>
                    {name}
                  </MenuItem>
                ))}
              </Select>
              <Select
                required
                name="type"
                label="Выберите Тип"
                formControlProps={{
                  margin: 'dense',
                  variant: 'outlined',
                }}
              >
                {types.map(({ name, value }) => (
                  <MenuItem key={name} value={value}>
                    {name}
                  </MenuItem>
                ))}
              </Select>
              {categories && (
                <Autocomplete
                  name="category"
                  options={categories?.filter(({ is_active }) => is_active)}
                  getOptionLabel={({ title }) => title}
                  filterSelectedOptions
                  disableClearable
                  renderInput={(params) => (
                    <MuiTextField
                      {...params}
                      variant="outlined"
                      label="Выберите рубрику"
                      margin="dense"
                    />
                  )}
                />
              )}
              <div>
                <TextField
                  required
                  id="outlined-error"
                  label="Название"
                  variant="outlined"
                  name="title"
                  margin="dense"
                />
              </div>
              <Button
                disabled={!valid || !userIsAuthor}
                type="submit"
                className={classes.submitBtn}
                variant="contained"
                color="primary"
              >
                Создать
              </Button>
            </Paper>
          </form>
        )}
      />
    </>
  );
};

export default NewArticle;
